import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { ParseService } from './parse.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate{

  constructor(private platform: Platform,private router: Router,private parse:ParseService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<any> | Promise<any> {
    return new Promise<any>((resolve) => {
      this.parse.user().currentAsync().then(user => {
        console.log('Logged user', user);
        if(user){
          let roles = next.data["roles"] as Array<string>;
          if(typeof roles!="undefined"){
            if(roles.includes(user.get("role"))){//Role authorized to access
              resolve(true);
            }else{//Role not authorized to access
              this.router.navigate(['dashboard']);
            }
          }else{
            resolve(true);
          }
        }else{
          this.router.navigate(['login']);
          resolve(false);
        }
      }, err => {
        console.log('Error getting logged user');
        this.router.navigate(['login']);
        resolve(false);
    
        // this.userLoggedin = false;
      })
   });
  }
}
