import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from './back-button/back-button.component';
import { IonicModule } from '@ionic/angular';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [BackButtonComponent,ValidationErrorComponent],
  imports: [
    CommonModule,IonicModule,ReactiveFormsModule
  ],
  exports:[BackButtonComponent,ValidationErrorComponent]
})
export class SharedModule { }
