import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoursMinutesPipe } from './hours-minutes.pipe';



@NgModule({
  declarations: [HoursMinutesPipe],
  imports: [
    CommonModule
  ],
  exports:[HoursMinutesPipe]
})
export class CustomPipesModule { }
