import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursMinutes'
})
export class HoursMinutesPipe implements PipeTransform {

  transform(value, ...args: unknown[]): unknown {
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    if(hours>0)
      return hours + " hrs, " + minutes + " mins";
    return minutes + " mins";
    // let minutes = Math.floor(value / 60);
    // let hours = Math.floor(minutes / 60);
    // let seconds = Math.floor(value % 60);
      
    // if(hours>0)
    //   return hours + " hrs, " + minutes + " mins, " + seconds + " secs";
    // if(minutes>0)
    //   return minutes + " mins, " + seconds + " secs";
      
    // return seconds + " secs";
  }

}
