import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Parse } from 'parse';
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class ParseService {
  parseSettings={
    'appid':"xkAjOzVBPHMiSnknHkXQ",
    // 'apikey':"QeWNumqNKdiJxPNlploa",
    // 'masterkey':"cKyTyCEoRFNYXhBHJrkF"
  }
  public userLoggedin:boolean=false;
  constructor(private router:Router) { 
    this.initialize();
  }
  async initialize(){
    // Parse.initialize(this.parseSettings.appid,this.parseSettings.apikey,this.parseSettings.masterkey);
    Parse.initialize(environment.parseSettings.apikey);
    Parse.serverURL=environment.parseSettings.endpoint;

    // let install = new Parse.Installation();
    // install.set("deviceType", this.platform.platforms().toString());
    // install.save(null, {
    //   success: (install) => {
    //     // Execute any logic that should take place after the object is saved.
    //     this.result = 'New object created with objectId: ' + install.id;
    //   },
    //   error: (install, error) => {
    //     // Execute any logic that should take place if the save fails.
    //     // error is a Parse.Error with an error code and message.
    //     this.result = ('Failed to create new object, with error code:' + error.message.toString());
    //   }
    // });
    this.checkLoggedIn();
  }
  getInstance(){
    return Parse;
  }
  newFile(filename:string,file,contenttype:string=null){
    if(contenttype===null){
      return new Parse.File(filename,file);
    }else{
      return new Parse.File(filename,file,contenttype);
    }
  }
  checkLoggedIn(){
    Parse.User.currentAsync().then(user => {
      if(user)
      this.userLoggedin = true;
    }, err => {
      this.userLoggedin = false;
    })
  }
  user(){
    return Parse.User;
  }
  logOut(){
    Parse.User.logOut().then(() => {
      this.userLoggedin=false;
      this.router.navigate(['login'],{replaceUrl: true});
    });
  }
  object(classname:string){
    switch (classname) {
      case 'role':{
        const ClassTemp=Parse.Object.extend(Parse.Role);
        return new ClassTemp();
        break;
      }
      case 'user':{
        const ClassTemp=Parse.Object.extend(Parse.User);
        return new ClassTemp();
        break;
      }
      default:{
        const ClassTemp=Parse.Object.extend(classname);
        return new ClassTemp();
        break;
      }
    }
  }

  query(classname:string){
    switch (classname) {
      case 'role':{
        const ClassTemp=Parse.Object.extend(Parse.Role);
        return new Parse.Query(ClassTemp);
        break;
      }
      case 'user':{
        const ClassTemp=Parse.Object.extend(Parse.User);
        return new Parse.Query(ClassTemp);
        break;
      }
      default:{
        const ClassTemp=Parse.Object.extend(classname);
        return new Parse.Query(ClassTemp);
      }
    }
  }
  getCloudInstance(){
    return Parse.Cloud;
  }
  sendSms(mobile:Number,message:string=''){
    Parse.Cloud.run("helloWorld")
    .then((result)=>{
      console.log(result);
    }).catch(e=>console.log(e));
    // let result=await Parse.Cloud.run("Hello");
    // Parse.Cloud.run('sendSms', {mobile: mobile, message: message})
    //   .then((result)=>{
    //       //do neat stuff
    //       console.log(result);
    //   }).catch(e=> {
    //      //error
    //      console.log(e);
    //   });
  }
}
